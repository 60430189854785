import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

export function ReactHookTagInput({
  name = "tags",
  label,
  fullWidth = false,
}: {
  name: string;
  label: string;
  fullWidth?: boolean;
}) {
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:Autocomplete",
  });
  return (
    <Controller
      name={name}
      render={({ field }) => (
        <Autocomplete
          noOptionsText={t("noOptions")}
          freeSolo
          multiple
          id="tags"
          options={[]}
          clearOnBlur
          {...field}
          onChange={(_, tags) => {
            field.onChange([...new Set(tags)]);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              fullWidth={fullWidth}
              onBlur={(e) => {
                const newTag = e.target.value.trim();
                if (newTag) {
                  field.onChange([...new Set([...field.value, newTag])]);
                }
              }}
            />
          )}
        />
      )}
    />
  );
}
