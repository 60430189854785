import { Control, useController } from "react-hook-form";
import Stack from "@mui/material/Stack";
import { ALL_VALUE } from "config";
import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";

export interface ReactHookViewablePermissionMultiselectProps
  extends Partial<AutocompleteProps<any, boolean, boolean, boolean, any>> {
  name: string;
  label: string;
  options: { label: string; value: any }[];
  control?: Control<any, any>;
  testId?: string;
  onChangeCallback?: () => void;
  required?: boolean;
  allValue?: string;
}

export function ReactHookViewablePermissionMultiselect({
  name,
  label,
  options,
  disabled = false,
  loading = false,
  loadingText,
  control,
  testId = "viewable-permissions-multiselect",
  onChangeCallback,
  sx,
  required = false,
  allValue = ALL_VALUE,
}: ReactHookViewablePermissionMultiselectProps) {
  const { t } = useTranslation("Fixhub");
  const {
    field: { onChange, value: values = [] },
    fieldState: { error },
  } = useController({
    control,
    name,
    rules: {
      required: required
        ? t("Next:Core:FieldRequiredValidationMessage")
        : false,
    },
  });
  const selectedValues = values
    .map((val: any) =>
      options.find(({ value: currentValue }) => currentValue === val)
    )
    .filter(Boolean);

  return (
    <Stack flex={1}>
      <Autocomplete
        noOptionsText={t("Next:Core:Autocomplete.noOptions")}
        data-testid={testId}
        value={selectedValues}
        options={loading ? [] : options}
        isOptionEqualToValue={(option, currentValue) =>
          option?.value === currentValue?.value
        }
        onChange={(_, selectedOptions) => {
          const lastSelected = selectedOptions.at(-1);
          if (lastSelected?.value === allValue) {
            onChange([allValue]);
          } else {
            const filteredSelections = selectedOptions
              .filter((selectedOption) => selectedOption?.value !== allValue)
              .map((selectedOption) => selectedOption?.value);
            onChange(filteredSelections);
          }
          if (typeof onChangeCallback === "function") onChangeCallback();
        }}
        sx={sx}
        multiple
        loading={loading}
        loadingText={loadingText}
        limitTags={2}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            error={Boolean(error)}
            helperText={error?.message}
            label={required ? `* ${label} ` : label}
          />
        )}
      />
    </Stack>
  );
}
