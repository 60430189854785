import { useCallback } from "react";
import { BrandCode, RegionCode } from "core/entities";
import {
  brandRegionCodeRegionMap,
  brandCodes as availableBrandCodes,
  regionCodes as availableRegionCodes,
  BrandRegionCode,
} from "brandRegionConfig";
import { useTranslation, TFunction } from "react-i18next";
import { isIncluded } from "core/utils/isIncluded";

export type ProcessedBrandRegionData = {
  brandOptions: { label: string; value: string }[];
  regionOptions: { label: string; value: string }[];
  brandCodes: BrandCode[];
  brandRegionMap: Partial<Record<BrandCode, RegionCode[]>>;
};

export type ApiBrandRegionData = {
  brand: string;
  regions: string[];
}[];

export function processBrandRegionData(
  data: ApiBrandRegionData | undefined,
  formatter: TFunction<string, undefined>,
  potentialBrandCodes: BrandCode[],
  potentialRegionCodes: readonly RegionCode[],
  brandRegionToRegionCodeMap: Record<BrandRegionCode, RegionCode>
): ProcessedBrandRegionData {
  if (!data) {
    return {
      brandOptions: [],
      regionOptions: [],
      brandCodes: [],
      brandRegionMap: {},
    };
  }
  const brandCodes = new Set<BrandCode>();
  const regionCodes = new Set<RegionCode>();
  const brandRegionMap = new Map<BrandCode, RegionCode[]>();

  // validate the brand and region
  data.forEach(({ brand, regions }) => {
    const potentialBrandCode = brand.toLowerCase();
    const isValidBrandCode = isIncluded(
      potentialBrandCodes,
      potentialBrandCode
    );
    if (!isValidBrandCode) return;
    const validRegionCodes: RegionCode[] = [];
    regions.forEach((region) => {
      // validate region code, or try to extract map from brandRegionCodeRegionMap
      const potentialRegionCode = region.toLowerCase();
      const isValidRegionCode = isIncluded(
        potentialRegionCodes,
        potentialRegionCode
      );
      if (isValidRegionCode) {
        validRegionCodes.push(potentialRegionCode);
        return;
      }
      const mappedRegionCode = brandRegionToRegionCodeMap[
        potentialRegionCode as BrandRegionCode
      ] as RegionCode | undefined;
      if (!mappedRegionCode) {
        return;
      }
      validRegionCodes.push(mappedRegionCode);
    });
    if (!validRegionCodes.length) return;
    brandRegionMap.set(potentialBrandCode, validRegionCodes);
    validRegionCodes.forEach((regionCode) => regionCodes.add(regionCode));
    brandCodes.add(potentialBrandCode);
  });
  const brandOptions = Array.from(brandCodes).map((brandCode) => ({
    label: formatter(`Brand:BrandName.${brandCode}`),
    value: brandCode,
  }));
  const regionOptions = Array.from(regionCodes).map((regionCode) => ({
    label: formatter(`Region:RegionName.${regionCode}`),
    value: regionCode,
  }));

  return {
    brandOptions,
    regionOptions,
    brandCodes: Array.from(brandCodes),
    brandRegionMap: Object.fromEntries(brandRegionMap),
  };
}

export function useProcessBrandRegionData() {
  const { t } = useTranslation("Fixhub");
  const processDataFunction = useCallback(
    (data: ApiBrandRegionData | undefined) =>
      processBrandRegionData(
        data,
        t,
        availableBrandCodes,
        availableRegionCodes,
        brandRegionCodeRegionMap
      ),
    [t]
  );

  return processDataFunction;
}
