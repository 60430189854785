import { useMemo } from "react";
import { FixnRoleType, IRoleDto } from "resources/APIModel";
import { ALL_VALUE } from "config";
import { useTranslation } from "react-i18next";
import { useAPIAll } from "./useAPIAll";

export function useRoleOptions(type: FixnRoleType) {
  const { data, isError, isLoading } = useAPIAll<IRoleDto[]>({
    path: "role",
    params: { type },
  });
  const { t } = useTranslation("Fixhub", {
    keyPrefix: "Next:Core:useRoleOptions",
  });
  const allLabel =
    type === FixnRoleType.Corporate
      ? t("allCorporateRoles")
      : t("allShopRoles");
  const roleOptions = useMemo(() => {
    const options =
      data
        ?.map(({ id, name }) => ({ label: name, value: id }))
        .filter((entry): entry is { label: string; value: string } =>
          Boolean(entry.label && entry.value)
        ) || [];
    return [{ label: allLabel, value: ALL_VALUE }, ...options];
  }, [data, allLabel]);

  const idRoleMap =
    data?.reduce<Record<string, string>>((acc, { id, name }) => {
      if (id === undefined || name === undefined) return acc;
      return { ...acc, [id]: name };
    }, {}) || {};

  return { data, isError, isLoading, roleOptions, idRoleMap };
}
