import { Controller, Control } from "react-hook-form";
import FormControlLabel, {
  FormControlLabelProps,
} from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export interface ReactHookCheckboxProps
  extends Omit<Partial<FormControlLabelProps>, "control" | "defaultValue"> {
  name: string;
  label: string;
  control?: Control<any, any>;
  defaultValue?: boolean;
  testId?: string;
  onChangeCallback?: () => void;
}

export function ReactHookCheckbox({
  name,
  label,
  control,
  defaultValue = false,
  sx,
  labelPlacement = "start",
  onChange,
  onChangeCallback,
  testId = "react-hook-checkbox",
  ...props
}: ReactHookCheckboxProps) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <FormControlLabel
          label={label}
          labelPlacement={labelPlacement}
          {...props}
          sx={{ m: 0, ...sx }}
          control={
            <Checkbox
              {...field}
              data-testid={testId}
              onChange={(e, checked) => {
                if (typeof onChange === "function") {
                  const result = onChange(e, checked);
                  field.onChange(result);
                } else {
                  field.onChange(e, checked);
                }
                if (typeof onChangeCallback === "function") {
                  onChangeCallback();
                }
              }}
              checked={field.value || defaultValue}
            />
          }
        />
      )}
    />
  );
}
