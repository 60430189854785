import {
  ChangeEventHandler,
  useRef,
  useState,
  MutableRefObject,
  DetailedHTMLProps,
  InputHTMLAttributes,
} from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import UploadIcon from "@mui/icons-material/Upload";
import { RegisterOptions, useController } from "react-hook-form";
import Avatar from "@mui/material/Avatar";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTranslation } from "react-i18next";
import useId from "@mui/utils/useId";
import LoadingButton from "@mui/lab/LoadingButton";
import { SvgFileIcon } from "../SvgFileIcon";

export interface ReactHookFileInputProps {
  label: string;
  name: string;
  fileNameName?: string;
  required?: boolean;
  rules?: Exclude<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs"
  >;
  inputProps?: DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  clearInputAfterChange?: boolean;
}

export function ReactHookFileInput({
  label,
  name,
  fileNameName = "fileName",
  required,
  rules = {},
  onChange: customOnChange,
  inputProps,
  clearInputAfterChange = false,
}: ReactHookFileInputProps) {
  const { t } = useTranslation("Fixhub");
  const {
    field: { onChange: fileInputOnChange, onBlur, ref },
    fieldState: { error },
  } = useController({
    name,
    rules: {
      required: required
        ? t("Next:Core:FieldRequiredValidationMessage")
        : false,
      ...rules,
    },
    defaultValue: "",
  });
  const {
    field: { onChange: fileNameOnChange, value: fileName },
  } = useController({
    name: fileNameName,
    defaultValue: "",
  });
  const errorMessage = error && error.message;
  const inputId = useId();
  const inputRef = useRef(null) as MutableRefObject<HTMLInputElement | null>;
  const [isChanging, setIsChanging] = useState(false);
  const onChange: ChangeEventHandler<HTMLInputElement> = customOnChange
    ? async (e) => {
        if (!e) return;
        setIsChanging(true);
        const newValue = await customOnChange(e);
        fileInputOnChange(newValue);
        setIsChanging(false);
        if (clearInputAfterChange) {
          if (inputRef.current !== null) {
            inputRef.current.value = "";
          }
        }
      }
    : (e) => {
        if (!e) return;
        setIsChanging(true);
        const file = e.target.files?.[0];
        if (file) {
          fileInputOnChange(file);
        }
        setIsChanging(false);
        if (clearInputAfterChange) {
          if (inputRef.current !== null) {
            inputRef.current.value = "";
          }
        }
      };
  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      maxWidth="100%"
      overflow="hidden"
    >
      <FormControl
        htmlFor={inputId}
        component="label"
        sx={{ minWidth: "240px" }}
      >
        <input
          ref={(e) => {
            ref(e);
            inputRef.current = e;
          }}
          type="file"
          name={name}
          id={inputId}
          onBlur={onBlur}
          onChange={onChange}
          style={{ display: "none" }}
          {...inputProps}
        />
        <LoadingButton
          variant="outlined"
          color="primary"
          loadingPosition="start"
          sx={{ width: "100%" }}
          loading={isChanging}
          startIcon={<UploadIcon />}
          onClick={() => {
            if (inputRef.current !== null) {
              inputRef.current.click();
            }
          }}
        >
          {required ? "* " : ""} {label}
        </LoadingButton>
        {errorMessage && (
          <FormHelperText sx={{ color: "error.main" }}>
            {errorMessage}
          </FormHelperText>
        )}
      </FormControl>
      {fileName && (
        <>
          <Avatar
            sx={{
              bgcolor: "divider",
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            <SvgFileIcon fileName={fileName} />
          </Avatar>
          <Typography sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>
            {fileName}
          </Typography>
          <IconButton
            onClick={() => {
              if (inputRef.current !== null) {
                inputRef.current.value = "";
              }
              fileNameOnChange("");
              fileInputOnChange("");
            }}
          >
            <CancelIcon />
          </IconButton>
        </>
      )}
    </Stack>
  );
}
