import { Control, RegisterOptions, useController } from "react-hook-form";
import { DateRangePicker, DateRangePickerProps } from "@mui/x-date-pickers-pro";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { useTranslation } from "react-i18next";

export interface ReactHookDateRangePickerProps
  extends DateRangePickerProps<any> {
  fromName: string;
  toName: string;
  fromLabel: string;
  toLabel: string;
  required?: boolean;
  rules?: Exclude<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs"
  >;
  control?: Control<any, any>;
  onChangeCallback?: (from: Date, to: Date) => void;
}

export function ReactHookDateRangePicker({
  fromName,
  toName,
  fromLabel,
  toLabel,
  control,
  required,
  rules,
  onChangeCallback,
  ...dateRangePickerProps
}: ReactHookDateRangePickerProps) {
  const { t } = useTranslation("Fixhub");

  function isValidDate(value: unknown) {
    const isInstanceOfDate = value instanceof Date;
    if (!isInstanceOfDate) {
      return t("Next:Core:FieldRequiredValidationMessage");
    }
    const notInvalidDate = !Number.isNaN(value.getTime());
    return notInvalidDate || t("Next:Core:FieldRequiredValidationMessage");
  }

  const validate = required ? { isValidDate } : undefined;

  // startDate
  const {
    field: { value: fromValue, onChange: fromOnChange },
    fieldState: { error: fromError },
  } = useController({
    name: fromName,
    defaultValue: "",
    control,
    rules: {
      required: required && t("Next:Core:FieldRequiredValidationMessage"),
      validate,
      ...rules,
    },
  });

  // endDate
  const {
    field: { value: toValue, onChange: toOnChange },
    fieldState: { error: toError },
  } = useController({
    name: toName,
    defaultValue: "",
    control,
    rules: {
      required: required && t("Next:Core:FieldRequiredValidationMessage"),
      validate,
      ...rules,
    },
  });

  return (
    <DateRangePicker
      localeText={{
        start: required ? `* ${fromLabel}` : fromLabel,
        end: required ? `* ${toLabel}` : toLabel,
      }}
      value={[fromValue, toValue]}
      onChange={([newFrom, newTo]) => {
        fromOnChange(newFrom);
        toOnChange(newTo);
        if (typeof onChangeCallback === "function") {
          onChangeCallback(newFrom, newTo);
        }
      }}
      {...dateRangePickerProps}
      slotProps={{
        textField: ({ position }) => ({
          error: position === "start" ? Boolean(fromError) : Boolean(toError),
          helperText:
            position === "start" ? fromError?.message : toError?.message,
          InputProps: {
            endAdornment: <DateRangeIcon />,
          },
          ...dateRangePickerProps.slotProps,
        }),
      }}
    />
  );
}
